<template>
  <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
  -->
  <div
    v-if="!loading"
    style="display: flex; flex-direction: column; min-height: 100vh"
  >
    <TransitionedRouter v-if="layout" :key="layout" :layout="layout">
      <RouterView />
    </TransitionedRouter>
    <portal-target name="image-preview"></portal-target>
    <portal-target name="chat-container"></portal-target>
    <portal-target name="contactOps-container"></portal-target>
  </div>
</template>

<script>
import Moment from 'moment'
import TransitionedRouter from '@components/transitioned-router.vue'
import { authMethods, authComputed } from '@state/modules/auth'
import { PreferenceComputed } from '@state/modules/preference'
import {
  OrganizationMethods,
  OrganizationComputed,
} from '@state/modules/organization'
import { BrandingMethods } from '@state/modules/branding'
import { LicenseMethods } from '@state/modules/license'
import { getUserConsentApi } from '@modules/organization/privacy-setting-api'
import { updateApplicationLocale } from './i18n'
import { LANGUAGE_MAP } from '@utils/language-map'
import { generateFingerprint } from './utils/fingerprint'

export default {
  name: 'Flotomate',
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      const branding = this.$store.getters['branding/branding']
      const isPortalLogin = this.$store.getters['auth/isPortalLogin']
      const templateTitle = isPortalLogin
        ? (branding || {}).name
        : (branding || {}).technicianPortalTitle
      title = typeof title === 'function' ? title(this.$store) : title
      return title
        ? `${templateTitle ? `${templateTitle} - ` : ''}${title}`
        : templateTitle
      // return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
  components: { TransitionedRouter },
  data() {
    return { layout: null, loading: true, userConsent: {} }
  },
  computed: {
    ...authComputed,
    ...OrganizationComputed,
    ...PreferenceComputed,
  },
  watch: {
    $route: function () {
      if (!this.$route.name) {
        return
      }
      let layout
      if (!this.loggedIn) {
        layout = 'LoginLayout'
      } else {
        layout = 'Layout'
      }
      // select layout to show if layout is provided in meta then use it else use default layout
      if (this.$route.matched.length) {
        for (let i = 0; i < this.$route.matched.length; i++) {
          if (this.$route.matched[i].meta.layout) {
            layout = this.$route.matched[i].meta.layout
            break
          }
        }
      }
      this.layout = layout
    },
    'organization.defaultLanguage': {
      immediate: true,
      handler(value) {
        if (!this.loggedIn) {
          const lang = LANGUAGE_MAP[value]
          if (lang) {
            updateApplicationLocale(lang)
            Moment.locale(lang)
          }
        }
      },
    },
    isRtl: {
      immediate: true,
      handler(newValue) {
        if (this.loggedIn) {
          if (newValue) {
            document.body.classList.add('rtl')
          } else {
            document.body.classList.remove('rtl')
          }
        }
      },
    },
    organizationIsRtl: {
      immediate: true,
      handler(newValue) {
        if (!this.loggedIn) {
          if (newValue) {
            document.body.classList.add('rtl')
          } else {
            document.body.classList.remove('rtl')
          }
        }
      },
    },
    theme: {
      immediate: true,
      handler(newValue) {
        // document.body.setAttribute('data-theme', 'dark-theme1')
        if (newValue === 'dark_theme') {
          document.body.setAttribute('data-theme', 'dark-theme')
        } else {
          document.body.removeAttribute('data-theme')
        }
      },
    },
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.userConsent.enabled) {
          if (newValue.consentAcceptedTime === 0) {
            return this.$router.replace(
              this.$modules.getModuleRoute('auth', 'user-consent')
            )
          }
          if (
            newValue.consentAcceptedTime > 0 &&
            this.userConsent.enforceConsentOnUpdate &&
            newValue.consentAcceptedTime <
              this.userConsent.consentPolicyLastUpdatedOn
          ) {
            return this.$router.replace(
              this.$modules.getModuleRoute('auth', 'user-consent')
            )
          }
        }
      },
    },
  },

  created() {
    this.init()
  },
  methods: {
    ...authMethods,
    ...BrandingMethods,
    ...LicenseMethods,
    ...OrganizationMethods,
    getUserConsent() {
      return getUserConsentApi()
    },
    init() {
      const fingerprint = generateFingerprint()

      // Ensure this runs even if loggedIn is true
      let fingerprintPromise = Promise.resolve()
      if (!this.loggedIn && this.isPortalLogin) {
        fingerprintPromise = this.fingerprint({ fingerprint })
      }

      // Chain the fingerprint promise with the subsequent operations
      fingerprintPromise
        .then(() => {
          this.fetchOrganization()
          if (this.user && this.user.userLanguage) {
            const lang = LANGUAGE_MAP[this.user.userLanguage]
            if (lang) {
              updateApplicationLocale(lang)
              Moment.locale(lang)
            }
          }
          const whiteListPaths = ['register', 'activate', '404']
          const currentPath = window.location.pathname.replace('/', '')
          if (
            currentPath === 'register' &&
            whiteListPaths.indexOf(currentPath) === -1
          ) {
            window.location = '/register'
            return
          }
          if (whiteListPaths.indexOf(currentPath) >= 0) {
            this.loading = false
            return
          }
          const promiseList = [
            this.fetchBranding(),
            this.fetchLicense(),
            this.getUserConsent(),
          ]
          Promise.all(promiseList)
            .then(([_b, _l, consent]) => {
              this.userConsent = consent
              // if user is loggedout then we set organization language
              if (!this.loggedIn) {
                return Promise.resolve({})
              }
              this.loading = false
              return this.refreshUser({ pageReload: true }).then(() => {
                this.loading = false
              })
            })
            .then(() => {
              this.loading = false
            })
            .catch((e) => {
              // Handle errors
              if (
                e.response &&
                e.response.status !== 403 &&
                e.response.data &&
                e.response.data.code !== 'FAuthx13.template'
              ) {
                if (
                  e.response.data &&
                  e.response.data.code === 'FAuthx16.template'
                ) {
                  this.$router.replace({ name: 'login' })
                } else {
                  this.$router.replace({ name: '404' })
                }
              }
              this.loading = false
            })
        })
        .catch((e) => {
          this.loading = false
        })
    },
  },
}
</script>

<!-- This should generally be the only global CSS in the app. -->
<style lang="less">
@import '~@src/design/app.less';
</style>
