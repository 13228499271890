import axios from 'axios'
import Omit from 'lodash/omit'
import {
  successToast,
  errorToast,
  successNotification,
  errorNotification,
} from '@motadata/ui'
import { logout, getCurrentUser, isLoggedIn } from '@utils/auth'
import Bus from '@utils/emitter'
import { getRootTranslator } from '@utils/get-module-translator'

const __tc = getRootTranslator()

/**
 * @class Api
 * @typedef {import('@/node_modules/axios/index').AxiosRequestConfig} RequestConfig
 * @typedef {import('@/node_modules/axios/index').AxiosResponse} Response
 */
class Api {
  totalRequests = 0

  redirectedToLogin = false

  /**
   * @constructor
   */
  constructor() {
    this._client = null
    this.createApiClient()
  }

  /**
   * show notification
   * @param {string} message
   * @param {string} alertType
   */
  showToastNotification = (message, alertType) => {
    const func = alertType === 'success' ? successToast : errorToast
    func(message)
  }

  showBigNotification = (notificationConfig, type) => {
    const fn = type === 'success' ? successNotification : errorNotification
    fn(Omit(notificationConfig, ['error']))
  }

  /**
   * Destroy api client
   */
  destroyClient() {
    this._client = null
  }

  /**
   * show progress loader
   */
  showProgressLoader = () => {
    this.totalRequests++
    // @TODO show progress loader here
  }

  /**
   * hide request progress loader
   */
  hideProgressLoader = () => {
    this.totalRequests--
    if (this.totalRequests < 0) {
      this.totalRequests = 0
    }
    if (this.totalRequests === 0) {
      // @TODO hide progress loader
    }
  }

  /**
   * Intercept all outgoing request
   * @param {RequestConfig} config
   * @returns {RequestConfig}
   */
  requestInterceptor = (config) => {
    config = Object.assign(config, config.data.__metadata)
    delete config.data.__metadata
    if (config.data.__qs) {
      config.data = config.data.__qs
    }
    if (config.loader !== false) {
      this.showProgressLoader()
    }
    return config
  }

  /**
   * Handle success resposne
   * @param {Response<object>} response
   * @returns {object}
   */
  responseSuccessHandler = (response) => {
    if (response.config.loader !== false) {
      this.hideProgressLoader()
    }
    if (
      (['post', 'patch', 'delete'].indexOf(
        response.config.method.toLowerCase()
      ) >= 0 ||
        response.config.message ||
        response.config.notification) &&
      response.config.notify !== false
    ) {
      requestAnimationFrame(() => {
        if (
          response.config.notification &&
          response.config.notification.message
        ) {
          this.showBigNotification(response.config.notification, 'success')
        } else {
          if (response.config.message) {
            this.showToastNotification(
              response.config.message || 'The Operation has been completed.',
              'success'
            )
          }
        }
      })
    }
    return response.data
  }

  /**
   * handle response error
   * @param {Response<object>} error
   * @return {Promise<Resposne>}
   */
  responseErrorHandler = (error) => {
    const isPublicApi = error.config.url.includes('/public/')

    if (
      (error.response || {}).status === 401 ||
      ((error.response || {}).data || {}).code === 'FAuthx17.template' ||
      ((error.response || {}).data || {}).code === '830'
    ) {
      if (isPublicApi) {
        // Handle the public API 401 error, fetch latest public token reload window.
        return window.location.reload()
      } else {
        if (!this.redirectedToLogin) {
          this.redirectedToLogin = true
          if (isLoggedIn()) {
            const currentUser = getCurrentUser()
            logout()
            Bus.$emit('auth:logout', currentUser)
            setTimeout(() => {
              this.redirectedToLogin = false
            }, 500)
          }
        }
        return Promise.reject(error)
      }
    }

    // Existing error handling logic
    if (axios.isCancel(error) || error.config.loader !== false) {
      this.hideProgressLoader()
    }
    if (axios.isCancel(error) || error.config.notify === false) {
      return Promise.reject(error)
    }
    if ((error.response || {}).status !== 401) {
      let message =
        ((error.response || {}).data || {}).userMessage ||
        __tc('unable_to_connect_server')

      if (
        error.code === 'ECONNABORTED' &&
        error.message.indexOf('timeout') >= 0
      ) {
        message = 'Request timeout reached, Please Try again'
      }
      if ((error.config.notification || {}).error) {
        this.showBigNotification(
          {
            ...error.config.notification.error,
            description:
              (error.response.data || {}).userMessage ||
              error.config.notification.error.description,
          },
          'error'
        )
      } else {
        requestAnimationFrame(() => {
          this.showToastNotification(message, 'error')
        })
      }
    }
    return Promise.reject(error)
  }

  /**
   * Create Api client
   */
  createApiClient() {
    const headers = { 'Content-Type': 'application/json' }
    this._client = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_PATH,
      timeout: 120000,
      headers,
    })
    this._client.interceptors.request.use(this.requestInterceptor)
    this._client.interceptors.response.use(
      this.responseSuccessHandler,
      this.responseErrorHandler
    )
  }

  /**
   * Apply header to all requests
   * @param {[key: string]: string} headers
   */
  setHeaders(headers) {
    if (!this._client) {
      throw new Error('no client found')
    }
    Object.keys(headers).forEach((key) => {
      this._client.defaults.headers.common[key] = headers[key]
    })
  }

  /**
   * remove header from api client
   * @param {string} headerName
   */
  removeHeader(headerName) {
    if (!this._client) {
      throw new Error('no client found')
    }
    delete this._client.defaults.headers.common[headerName]
  }

  /**
   * Send Actual request
   * @param {RequestConfig} config
   * @returns {Promise<Response>}
   */
  _sendRequest(config) {
    const fireRequest = (resolve, reject) => {
      if (!this._client) {
        try {
          this.createApiClient()
        } catch (e) {
          // eslint-disable-next-line
          console.error(e)
          return reject(e)
        }
      }
      if (config.timeout !== undefined) {
        this._client.defaults.timeout = config.timeout
      }
      return this._client
        .request({
          ...config,
        })
        .then(resolve, reject)
    }
    return new Promise((resolve, reject) => fireRequest(resolve, reject))
  }

  /**
   * send get request
   * @param {string} url
   * @param {RequestConfig} config
   */
  get(url, config) {
    return this._sendRequest({
      ...config,
      url,
      method: 'get',
      data: {
        __metadata: { ...config },
      },
    })
  }

  /**
   * Send post request
   * @param {string} url
   * @param {object|any} data
   * @param {RequestConfig} config
   */
  post(url, data, config) {
    if (typeof data !== 'string') {
      if (data) {
        data.__metadata = config
      } else {
        data = { __metadata: config }
      }
    } else {
      data = { __qs: data, __metadata: config }
    }
    return this._sendRequest({
      ...config,
      url,
      data,
      method: 'post',
    })
  }

  /**
   * Send patch request
   * @param {string} url
   * @param {object|any} data
   * @param {RequestConfig} config
   */
  patch(url, data, config) {
    if (typeof data !== 'string') {
      if (data) {
        data.__metadata = config
      } else {
        data = { __metadata: config }
      }
    } else {
      data = { __qs: data, __metadata: config }
    }
    return this._sendRequest({
      ...config,
      url,
      data,
      method: 'patch',
    })
  }

  /**
   * send delete request
   * @param {string} url
   * @param {RequestConfig} config
   */
  delete(url, config) {
    return this._sendRequest({
      ...config,
      data: {
        ...(config.data || {}),
        __metadata: { ...config },
      },
      url,
      method: 'delete',
    })
  }
}

export default Api
